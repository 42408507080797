.gb-modal-layout {
    width             : calc(100%);
    height            : 100%;
    position          : fixed;
    z-index           : 1000;
    top               : 0;
    background-color  : rgba($color: #000000, $alpha: 0.5);
    // backdrop-filter: ;
    display           : none;
    color             : theme('colors.gb-gray.850');
    justify-content   : center;
    align-items       : center;
    padding           : 15px;
    overflow          : auto;


    &.modal-active {
        display: flex;
    }

    .gb-modal-container {
        width              : 100%;
        min-height         : 100px;
        background-color   : theme('colors.gb-gray.75');
        max-width          : 360px;
        border-radius      : 8px;
        border             : 1px solid theme('colors.gb-gray.200');
        position           : absolute;
        // margin-top: 100px;
        // padding         : 15px;
    }

    .date-time-moda-body {
        // background-color: red;
        max-width         : 800px;
        display           : flex;
        justify-content   : center;
        align-items       : center;
        // margin-left: -70px;
        // padding        : 15px;

        .MuiPickerStaticWrapper-root {

            .MuiSvgIcon-root {
                color: theme('colors.gb-gray.400');
            }

            // background-color: red;
            .MuiPickerStaticWrapper-content {
                background-color: transparent;
                color           : theme('colors.gb-gray.800');
            }

            .MuiTypography-root {
                // background-color: red;
                color      : theme('colors.gb-blue.700');
                font-size  : 15px;
                font-weight: 600;
            }

            .MuiPickersDay-root {
                background-color: transparent;
                color           : theme('colors.gb-gray.900');

                &.Mui-selected {
                    background-color: theme('colors.gb-blue.600');
                }

                &.Mui-disabled {
                    // background-color: red;
                    color: theme('colors.gb-gray.500');
                }
            }

        }

        .MuiClockPicker-root {
            .css-1umqo6f {
                // background-color: red;
                // padding: 100px;
                background-color: transparent;
            }

            .css-12ha4i7,
            .css-a1rc6s,
            .css-118whkv,
            .css-a1rc6s,
            .css-7lip4c {
                background-color: theme('colors.gb-blue.700');
            }

            .css-118whkv,
            .css-2ujp1m,
            .css-2ujp1m {
                border-color: theme('colors.gb-blue.700');
            }

            .css-1flhz3h,
            .css-x9nfkm {
                color: theme('colors.gb-gray.800');
            }

            .css-13go44a-MuiButtonBase-root-MuiIconButton-root,
            .css-dgcnuc,
            .css-ocomm,
            .css-1t8wyba-MuiButtonBase-root-MuiIconButton-root {
                background-color: theme('colors.gb-blue.700');
            }

            .MuiTypography-root {
                color: theme('colors.gb-gray.900');
            }

        }

    }





}