@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html,
body,
label {
  font-family: 'Roboto', sans-serif;
}

.p-button.p-button-icon-only {
  width: 50px !important
}

.p-timeline-event-opposite {
  display: none;
}

.blackAndWhiteFilter {
  filter: gray;
  opacity: 0.1;
}

@layer base {

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;

  }

  /* ... */
}

@layer components {
  .card {
    background-color: #1e1e1e;
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.3');
    box-shadow: theme('boxShadow.xl');
  }

  .cardImg {
    background-color: #1e1e1e;
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.0');
    box-shadow: theme('boxShadow.xl');
  }
}

@media(max-width:768px) {
  .p-fileupload .p-fileupload-buttonbar {
    display: grid !important;
    gap: 6px !important;
  }
}

.p-tabmenu .p-tabmenu-nav li.p-highlight .p-menuitem-link:focus {
  background-color: #1e1e1e !important;
}

/* .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  color: #fff !important;
} */
.p-skeleton {
  background-color: #1e1e1e !important;
}

:root {
  --primary-50: #fdfafd !important;
  --primary-100: #f3e5f6 !important;
  --primary-200: #ead1ee !important;
  --primary-300: #e1bce7 !important;
  --primary-400: #662d91 !important;
  --primary-500: #662d91 !important;
  --primary-600: #8e48a2 !important;
  --primary-700: #af6cb4 !important;
  --primary-800: #c98dd1 !important;
  --primary-900: #e0b2e9 !important;
  --primary-color: #662d91 ! important;
}

.p-tag.p-tag-info {
  /* background-color: #ce93d8 !important; */
  background-color: #9fa8da !important;
}

.p-breadcrumb {
  border: none !important;
}

@media(min-width:1400px) {
  .layout-main {
    margin: 4rem auto 0px auto !important;
  }
}

label {
  font-weight: 700 !important;
}
.jodit-status-bar{
  background-color: #1e1e1e !important;
}
.p-inputtext:enabled:hover{
  background-color: #ffffff0a !important;
}