.place-menu {
    // background-color: red;
    display       : flex;
    flex-direction: column;
    position      : fixed;
    bottom        : 20px;
    right         : 20px;



    .animated-button {
        transform          : translateY(-100px);
        // background-color: red;
    }

    .block-option {

        background-color: red;
    }


}



.place-menu-button {
    background-color: theme("colors.gb-gray.150");
    width           : 56px;
    height          : 56px;
    border-radius   : 50%;
    display         : flex;
    justify-content : center;
    align-items     : center;
    transition      : background-color 0.5s;
    border          : 1px solid theme('colors.gb-gray.300');
    margin          : 10px 0;

    .animate-button {
        transform: translateY(200px);
    }

    &.button-active {

        background-color: theme('colors.gb-blue.600');
        border          : 1px solid theme('colors.gb-gray.1000');

        svg {

            fill: theme('colors.gb-gray.1000');

        }


    }

    svg {
        width     : 20px;
        height    : 20px;
        fill      : theme("colors.gb-gray.850");
        transition: fill 0.5s;

        &.check {
            width : 40px;
            height: 40px;
        }

    }

    .badge {
        background-color: theme('colors.gb-pink.300');
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 24px;
        height          : 24px;
        border-radius   : 50%;

        svg {
            width: 14px;
        }

        &.block-option {
            background-color: theme('colors.gb-yellow.400');
        }

        &.recerve-option {
            background-color: theme('colors.gb-cyan.300');
        }

        &.coutesies-option {
            background-color: theme('colors.gb-green.200');
        }

    }

    &:hover {
        background-color: theme("colors.gb-blue.500");

        svg {
            fill: theme('colors.gb-gray.1000');
        }
    }

    &:active {
        background-color: theme('colors.gb-blue.400');

        svg {
            fill: theme('colors.gb-gray.1000');
        }

    }


}

.line-adding {

    display        : flex;
    width          : 100%;
    max-width      : 400px;
    position       : relative;
    align-items    : center;
    justify-content: center;

    svg {
        // background-color: red;
        fill      : theme('colors.gb-gray.400');
        transition: fill .5s;
    }



    .add-incon {


        display    : flex;
        padding    : 0 10px;
        align-items: center;

    }

    &::after,
    &::before {
        content         : '';
        width           : 150px;
        height          : 2px;
        display         : block;
        background-color: theme('colors.gb-gray.500');
        position        : absolute;
        transition      : background-color .5s;
    }

    &::before {
        right: 0;
    }

    &::after {
        left: 0;
    }

    &:hover {

        &::after,
        &::before {

            background-color: theme('colors.gb-gray.950');

        }

        svg {
            // background-color: red;
            fill: theme('colors.gb-gray.950');

        }
    }

}

.planel-option-button {
    background-color: theme('colors.green.700');

}

.delected-panel {

    position: relative;


    .planel-option-button {

        background-color: theme('colors.gb-gray.300');
        border          : 1px solid theme('colors.gb-gray.600');
        right           : 0;
        top             : 0;
        border-radius   : 50%;
        width           : 36px;
        height          : 36px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        // padding: 4px;


        font-size: 5px;
        color    : theme('colors.gb-gray.950');

        svg {
            width : 18px;
            height: 18px;
        }

        &:hover {
            background-color: theme('colors.gb-red.200');
        }


        transition: background-color .5s;

    }
}

.change-price-box {
    margin-top      : 15px;
    background-color: theme('colors.gb-gray.100');
    position        : relative;
    width           : 100%;
    min-height      : 1px;

    &.round {
        border-radius      : 8px;
        // background-color: red;

    }

    .succes-circle {
        width           : 60px;
        height          : 60px;
        border-radius   : 50%;
        background-color: theme('colors.gb-green.200');
        color           : theme('colors.gb-gray.800');
        display         : flex;
        justify-content : center;
        align-items     : center;
        margin-top      : 15px;
        border          : 1px solid theme('colors.gb-gray.400');

        svg {
            width : 30px;
            height: 30px;
        }
    }

    .btn-update {
        background-color: theme('colors.gb-blue.600');
        width           : 100%;
        padding         : 5px;
        border-radius   : 8px;
        border-radius   : 8px;
        transition      : background-color .5s;
        border          : 1px solid theme('colors.gb-gray.300');
        color           : theme('colors.gb-gray.850');

        &.bg-transparent {
            background-color: transparent;
        }

        &:hover {
            background-color: theme('colors.gb-blue.400');
        }

    }

    .planel-option-button {
        // background-color: theme('colors.green.700');
        background-color: theme('colors.gb-gray.300');
        border          : 1px solid theme('colors.gb-gray.600');
        position        : absolute;
        right           : 0;
        top             : 0;
        transform       : translateY(calc(-100% - 10px));
        border-radius   : 50%;
        width           : 36px;
        height          : 36px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        // padding: 4px;

        &.min-icon {

            // background-color: red;
            svg {
                width : 18px;
                height: 18px;
            }
        }

        font-size: 5px;
        color    : theme('colors.gb-gray.950');

        svg {
            width : 25px;
            height: 25px;
        }

        &:hover {
            background-color: theme('colors.gb-blue.600');
        }


        transition: background-color .5s;

    }

}

.edidt-form {

    .info {
        font-size  : 13px;
        text-align : justify;
        line-height: 18px;
        padding    : 0;
    }

    // background-color: red;


    .gb-form-control {
        span {
            color: theme('colors.gb-gray.850');
        }
    }

    .gb-input {
        background-color: theme('colors.gb-gray.150');
    }

    .error-meessage {

        color: theme('colors.gb-red.500') !important;
    }
}



.icon-button {
    background-color: theme('colors.gb-gray.150');
    border-radius   : 50%;
    margin          : 0 2px;
    width           : 20px;
    height          : 20px;
    display         : flex;
    justify-content : center;
    align-items     : center;

    svg {
        width : 16px;
        height: 16px;
        fill  : theme('colors.gb-gray.800');
    }
}

.chairs-bloque-box {
    background-color: theme('colors.gb-gray.75');
    border-radius   : 8px;
    border          : 1px solid theme('colors.gb-gray.300');



    .add-new-chairs-button,
    .remove-chairs-button {
        color          : theme('colors.gb-gray.800');
        margin         : 0 5px;
        border         : 1px dashed theme('colors.gb-gray.200');
        display        : flex;
        justify-content: center;
        align-items    : center;
        border-radius  : 8px;
        transition     : background-color .5s;
    }

    .add-new-chairs-button {
        &:hover {
            background-color: theme('colors.gb-blue.600');
        }
    }

    .remove-chairs-button {
        &:hover {
            background-color: theme('colors.gb-red.200');
        }
    }


}

.delete-modal-body {

    .btn-delete {
        width          : 100%;
        height         : 40px;
        transition     : background-color .5s, border .5s;
        border         : 1px solid transparent;
        display        : flex;
        justify-content: center;
        align-items    : center;
        border-radius  : 8px;

        &:hover {
            background-color: theme('colors.gb-blue.600');
        }

        &:disabled {
            opacity         : 50%;
            background-color: theme('colors.gb-gray.200');
            color           : theme('colors.gb-gray.950');

        }

    }

    .gb-btn-primary {
        background-color: theme('colors.gb-blue.500');
        border          : 1px solid;
        border-color    : theme('colors.gb-gray.600');

    }

    .input-delete {
        background-color: theme('colors.gb-gray.50');
        padding         : 10px;
        border-radius   : 8px;
        border          : 1px solid theme('colors.gb-gray.450');

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: theme('colors.gb-gray.250');
        }
    }
}

.gb-bloque-seccion {
    margin-top     : 60px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    padding        : 10px;

    .gb-bloque-header {
        width: 100%;
    }

    .place-secction {
        display       : flex;
        flex-direction: column;
        width         : 100%;
    }

    .gb-place-row {

        display    : flex;
        width      : 100%;
        align-items: center;



        .row-tag {
            width              : 50px;
            // background-color: red;
            color              : theme('colors.gb-gray.750');
            display            : flex;
            justify-content    : center;
        }

        .chairs-secction {
            width              : 100%;
            // background-color: blue;
            justify-content    : center;
            display            : flex;
            flex-wrap          : wrap;
        }
    }

    .gb-chairs-tooltip {
        // background-color: theme;
        position        : absolute;
        z-index         : 10;
        background-color: theme('colors.gb-gray.150');
        min-width       : 60px;
        display         : none;
        justify-content : center;
        align-items     : center;
        border-radius   : 8px;
        border          : 1px solid theme('colors.gb-gray.350');
        bottom          : calc(100% + 20px);
        margin          : auto;


        .gb-chairs-tooltip-body {
            background-color: theme('colors.gb-gray.150');
            padding         : 6px;
            width           : 100%;
            border-radius   : 8px;

            // seccion de herader 

            .header-pos-box {
                display        : flex;
                justify-content: center;
                align-items    : center;

                .header-post-item {
                    display        : flex;
                    flex-direction : column;
                    justify-content: center;
                    align-items    : center;
                    width          : 100%;
                    min-width      : 40px;

                    span {
                        color    : theme('colors.gb-gray.600');
                        font-size: 13px;
                    }

                    h4 {
                        font-size: 18px;
                    }

                }


            }

            .divider-y {
                background-color: theme('colors.gb-marino.600');
                width           : 1px;
                // height: 100%;
                height          : 30px;
            }

            .divider-x {
                background-color: theme('colors.gb-gray.400');
                width           : 100%;
                height          : 1px;
                margin          : 4px 0;
            }

            .info-box {
                display        : flex;
                flex-wrap      : wrap;
                justify-content: center;
                align-items    : flex-start;
            }

            .info-key {
                font-size: 12px;
                color    : theme('colors.gb-gray.600');
            }

            .info-content {
                font-size: 14px;
                color    : theme('colors.gb-gray.750');
            }

        }

        .gb-chairs-tooltip-arrow {
            width           : 20px;
            height          : 20px;
            background-color: theme('colors.gb-gray.150');
            position        : absolute;
            z-index         : -1;
            transform       : rotate(45deg);
            bottom          : -10px;
            border          : 1px solid theme('colors.gb-gray.350');

        }

    }

    .chairs-box {
        display: flex;
    }

    .place-selected {



        // background-color: red;
        // opacity: .5;
        .line-chairs {
            fill      : theme('colors.gb-gray.900') !important;
            transition: fill .5s;
        }

        .circle-chairs {
            fill: theme('colors.gb-pink.600') !important
        }

        // background-color: gray;

    }


    .dynamic-chairs-icon {


        .line-chairs {
            fill      : theme('colors.gb-gray.400');
            transition: fill .5s;
        }

        .circle-chairs {
            // fill:  theme('colors.gb-gray.600');
            fill: theme('colors.gb-marino.500')
        }

        &.chairs-block {

            .line-chairs {
                fill: theme('colors.gb-gray.500');

            }

            .circle-chairs {
                fill: theme('colors.gb-gray.300')
            }


        }

        
        &.chairs-block-su {

            .line-chairs {
                fill: #a13939;

            }

            .circle-chairs {
                fill: #a13939
            }


        }

        &.chairs-recerved {

            .line-chairs {
                fill: theme('colors.gb-gray.500');

            }

            .circle-chairs {
                fill: theme('colors.gb-yellow.700')
            }

        }

        &.chairs-invisible {

            .line-chairs {
                fill: theme('colors.gb-gray.300');

            }

            .circle-chairs {
                fill: theme('colors.gb-gray.100')
            }


        }

        &.cortesies-block {
            // background-color: red;

            .line-chairs {
                fill      : #a13939;
                transition: fill .5s;
            }

        }
    }


    .dynamic-chairs-button {


        // background-color: red;
        display        : flex;
        justify-content: center;
        align-items    : center;
        z-index        : 10;

        &:focus {
            outline: none;
            border : none;
        }


        .circle-selected {
            width           : 26px;
            height          : 26px;
            background-color: #663399;
            position        : absolute;
            z-index         : -2;
            border-radius   : 50%;
        }

        &:hover {
            .gb-chairs-tooltip {
                display: flex;
            }

            .dynamic-chairs-icon {



                .line-chairs {
                    fill: theme('colors.gb-gray.700');

                }

                .circle-chairs {
                    // fill:  theme('colors.gb-gray.600');
                    fill: theme('colors.gb-marino.700')
                }

                &.chairs-block {

                    .line-chairs {
                        fill: theme('colors.gb-gray.300');

                    }

                    .circle-chairs {
                        fill: theme('colors.gb-gray.200')
                    }


                }

                &.chairs-block-su {

                    .line-chairs {
                        fill: #a13939;

                    }

                    .circle-chairs {
                        fill: theme('colors.gb-red.200')
                    }


                }

                &.chairs-recerved {

                    .line-chairs {
                        fill: theme('colors.gb-gray.400');

                    }

                    .circle-chairs {
                        fill: theme('colors.gb-yellow.500')
                    }

                }

                &.chairs-invisible {

                    .line-chairs {
                        fill: theme('colors.gb-gray.500');

                    }

                    .circle-chairs {
                        fill: theme('colors.gb-gray.75')
                    }

                }



            }
        }

        &:active {

            .dynamic-chairs-icon {



                .line-chairs {
                    fill: theme('colors.gb-gray.700');

                }

                .circle-chairs {
                    // fill:  theme('colors.gb-gray.600');
                    fill: theme('colors.gb-blue.700')
                }


                &.chairs-block,
                &.chairs-recerved,
                &.chairs-invisible {

                    .line-chairs {
                        fill: theme('colors.gb-gray.400');

                    }

                    .circle-chairs {
                        fill: theme('colors.gb-blue.700')
                    }


                }



            }

        }
    }

}


.place-body-modal {
    padding            : 15px;
    // background-color: red;

    .loading-body {
        width          : 100%;
        min-height     : 300px;
        display        : flex;
        justify-content: center;
        flex-direction : column;
    }


}

.place-drop-dow-list-box {
    // background-color: red;
    max-height: 600px;
    overflow-y: auto;

    .drop-cards-item {
        display         : flex;
        flex-direction  : column;
        align-items     : center;
        justify-content : center;
        width           : 100%;
        background-color: theme('colors.gb-gray.100');
        min-height      : 20px;
        margin          : 8px 0;
        border-radius   : 8px;
        border          : 1px solid theme('colors.gb-gray.300');
        padding         : 10px;

        .place-posxy-header {
            display        : flex;
            width          : 100%;
            justify-content: space-between;
            align-items    : center;
            margin-top     : 5px;

            .place-posxy-item {
                flex           : 1;
                justify-content: center;
                display        : flex;
                flex-direction : column;
                justify-content: center;
                align-items    : center;

                h4 {
                    color    : theme('colors.gb-gray.600');
                    font-size: 20px;
                }

                span {
                    font-size: 18px;
                    color    : theme('colors.gb-gray.800');
                }

            }
        }

    }


}




.places-basic-info {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    padding        : 15px;


    .info-basic-box {

        // background-color: red;
        width          : 100%;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        max-height     : 500px;
        overflow       : auto;


        .info-bacis-card-item {
            // background-color: red;
            background-color: #191b1e;
            margin          : 5px 0;
            width           : 100%;
            padding         : 6px;
            border-radius   : 8px;
            border          : 1px solid theme('colors.gb-gray.200');
            display         : flex;
            flex-direction  : column;
            align-items     : center;



            .headers-xd {
                display        : flex;
                width          : 100%;
                justify-content: space-between;
                align-items    : center;

                .place-pos {
                    flex          : 1;
                    display       : flex;
                    flex-direction: column;
                    align-items   : center;


                    h4 {

                        font-size  : 16px;
                        font-weight: 600;
                        color      : theme('colors.gb-gray.600');
                    }

                    span {
                        font-size  : 16px;
                        font-weight: 300;
                        color      : theme('colors.gb-gray.900');
                    }

                    // .title {
                    //     background-color: rebeccapurple;
                    // }

                    .body-xd {}


                }
            }

            .body-xd {

                // background-color: red;

                .list {

                    display: flex;

                    .title-xd {
                        width    : 130px;
                        display  : block;
                        font-size: 14px;
                        color    : theme('colors.gb-gray.600');
                    }

                    .conten-xd {
                        font-size: 14px;
                        color    : theme('colors.gb-gray.700');
                    }
                }



            }

        }

    }
}

.place-table-map {
    .table-place-bloque {
        margin : 20px 0;
        padding: 30px;

        .table-place-filas {
            background-color: #121517;
            border          : 1px solid theme('colors.gb-gray.100');
            border-radius   : 15px;
            padding         : 15px;

        }

        .fila-enumerada {
            font-size: 15px;
            color    : theme('colors.gb-gray.800');
        }

        .table-place-fila {
            // background-color: red;
            display    : flex;
            padding    : 8px;
            align-items: center;


            &:nth-child(odd) {
                background-color: #0c0e0e;
                border-radius   : 8px;
            }


            .table-place-zone {
                display        : flex;
                width          : 100%;
                flex-wrap      : wrap;
                justify-content: center;
                align-items    : center;



            }

        }

        .mesa-box {
            width              : 64px;
            min-height         : 60px;
            margin             : 5px;
            position           : relative;
            // background-color: red;
            display            : flex;
            flex-wrap          : wrap;
            justify-content    : space-between;

        }


    }

    .charis-for-table {
        position       : relative;
        margin         : 1px 0;
        display        : flex;
        align-items    : center;
        outline        : none;
        justify-content: center;

        .chair-cover {
            width           : 100%;
            height          : 100%;
            position        : absolute;
            z-index         : 5;
        }

        .circle-selected {
            width           : 20px;
            height          : 20px;
            position        : absolute;
            border-radius   : 10px;
            background-color: #663399;

        }

        &:nth-child(odd) {
            position: relative;
            left    : 0;

            .circle-selected {
                opacity: 1;
                left   : 0;
            }


            .dynamic-chairs-icon {
                // background-color: red;
                transform   : rotate(-90deg);
                margin-right: 12px;


            }
        }

        &:nth-child(even) {


            .circle-selected {
                opacity: 1;
                right  : 0;
            }


            .dynamic-chairs-icon {
                // background-color: blue;
                transform  : rotate(90deg);
                margin-left: 12px;
            }
        }

        &:hover {
            .dynamic-chairs-icon {

                .gb-line {
                    fill      : theme('colors.gb-gray.200');
                    transition: fill .5s;

                }

                .gb-circle {
                    fill      : theme('colors.gb-purple.200');
                    transition: fill .5s;
                }


                &.chairs-recerved {

                    .gb-line {
                        fill      : theme('colors.gb-gray.200');
                        transition: fill .5s;

                    }

                    .gb-circle {
                        fill      : theme('colors.gb-yellow.300');
                        transition: fill .5s;
                    }

                }

                &.chairs-block {

                    .gb-line {
                        fill      : theme('colors.gb-gray.300');
                        transition: fill .5s;

                    }

                    .gb-circle {
                        fill      : theme('colors.gb-gray.300');
                        transition: fill .5s;
                    }

                }

                &.chairs-block-su {

                    .gb-line {
                        fill      : #a13939;
                        transition: fill .5s;

                    }

                    .gb-circle {
                        fill      : #a13939;
                        transition: fill .5s;
                    }

                }

                &.chairs-invisible {
                    .gb-line {
                        fill      : theme('colors.gb-gray.300');
                        transition: fill .5s;

                    }

                    .gb-circle {
                        fill      : theme('colors.gb-gray.75');
                        transition: fill .5s;
                    }
                }
            }

            .gb-chairs-tooltip {
                display: flex;
            }
        }

    }

    .dynamic-table {
        width           : 20px;
        height          : 100%;
        background-color: #384145;
        position        : absolute;
        z-index         : 10;
        left            : 0;
        right           : 0;
        margin          : auto;
        border-radius   : 8px;
        border          : 1px solid theme('colors.gb-gray.500');

        &:hover {
            .table-toogle {
                display: flex;
            }
        }

    }

    .dynamic-chairs-icon {


        .gb-line {
            fill      : theme('colors.gb-gray.300');
            transition: fill .5s;

        }

        .gb-circle {
            fill      : theme('colors.gb-purple.400');
            transition: fill .5s;
        }


        &.chairs-recerved {

            .gb-line {
                fill      : theme('colors.gb-gray.300');
                transition: fill .5s;

            }

            .gb-circle {
                fill      : theme('colors.gb-yellow.400');
                transition: fill .5s;
            }

        }

        &.chairs-block {

            .gb-line {
                fill      : theme('colors.gb-gray.300');
                transition: fill .5s;

            }

            .gb-circle {
                fill      : theme('colors.gb-gray.400');
                transition: fill .5s;
            }

        }

        &.chairs-block-su {

            .gb-line {
                fill      : #a13939;
                transition: fill .5s;

            }

            .gb-circle {
                fill      : #a13939;
                transition: fill .5s;
            }

        }

        &.chairs-invisible {
            .gb-line {
                fill      : theme('colors.gb-gray.300');
                transition: fill .5s;

            }

            .gb-circle {
                fill      : theme('colors.gb-gray.100');
                transition: fill .5s;
            }
        }

        &.cortesies-block {
            .gb-line {
                fill: theme('colors.gb-red.600');

            }
        }



    }

    .gb-chairs-tooltip {
        min-width       : 60px;
        min-height      : 20px;
        background-color: theme('colors.gb-gray.100');
        display         : none;
        position        : absolute;
        z-index         : 40;
        bottom          : calc(100% + 16px);
        border-radius   : 8px;
        justify-content : center;
        border          : 1px solid theme('colors.gb-gray.200');


        .list-box {
            display: flex;
            width  : 100%;

        }

        .header-pos-box {
            display        : flex;
            align-items    : center;
            justify-content: center;

            .header-pos-item {

                width         : 50px;
                display       : flex;
                flex-direction: column;
                align-items   : center;


                h5 {
                    // background-color: red;
                    font-size: 14px;
                    color    : theme('colors.gb-gray.600');
                }

                p {
                    color: theme('colors.gb-gray.900');
                }
            }
        }

        .list-box {
            h6 {
                font-size : 12px;
                color     : theme('colors.gb-gray.500');
                width     : 100px;
                text-align: left;
            }

            span {
                color    : theme('colors.gb-gray.900');
                font-size: 12px;
            }
        }


        .gb-chairs-tooltip-body {
            background-color: theme('colors.gb-gray.100');
            width           : 100%;
            min-height      : 10px;
            padding         : 5px;
            border-radius   : 8px;
            display         : flex;
            flex-direction  : column;
            justify-content : center;
            align-items     : center;
        }


        .gb-chairs-tooltip-arrow {
            width           : 20px;
            height          : 20px;
            background-color: theme('colors.gb-gray.100');
            position        : absolute;
            z-index         : -1;
            transform       : rotate(45deg);
            bottom          : -10px;
            border          : 1px solid theme('colors.gb-gray.200');
        }



    }
}


.place-table-button-ar {
    position   : relative;
    display    : flex;
    align-items: center;
}

.table-button {
    background-color: theme('colors.gb-purple.600');
    color           : theme('colors.gb-gray.950');
    border          : 2px solid theme('colors.gb-gray.400');
    width           : 30px;
    height          : 30px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    border-radius   : 50%;
    position        : absolute;

    transition: background-color .5s;

    &:hover {
        background-color: theme('colors.gb-purple.400');

    }

    &:first-child {
        left: -20px;
    }

    &:last-child {
        right: -20px;
    }

}


.chairs-box-button-zone {
    width           : 60px;
    height          : 60px;
    border-radius   : 50%;
    background-color: theme('colors.gb-gray.200');
    border          : 2px solid theme('colors.gb-gray.400');
    display         : flex;
    justify-content : center;
    align-items     : center;

    svg {
        fill  : theme('colors.gb-gray.700');
        width : 25px;
        height: 25px;
    }

}

.add-new-table-button {
    // background-color: red;
    background-color: theme('colors.gb-gray.250');
    border          : 1.5px solid theme('colors.gb-gray.300');
    border-radius   : 50%;
    color           : theme('colors.gb-gray.900');
    width           : 20px;
    height          : 20px;
    display         : flex;
    justify-content : center;
    align-items     : center;

    transition: background-color .5s;

    &:hover {
        background-color: theme('colors.gb-purple.600');
    }

    svg {
        width : 16px;
        height: 16px;
    }

}

.table-ar-button {

    width        : 100%;
    height       : 50%;
    border-radius: 8px;

    svg {
        width : 16px;
        height: 16px;
    }
}

.general-place-v2 {

    width          : 100%;
    display        : flex;
    justify-content: center;
}

.general-place-body {
    align-items     : center;
    flex-direction  : column;
    background-color: theme('colors.gb-gray.75');
    border          : 1.5px solid theme('colors.gb-gray.350');
    width           : 100%;
    max-width       : 300px;
    padding         : 15px;
    border-radius   : 8px;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;

    h5 {
        color: theme('colors.gb-gray.550');
    }

}

.zone-info {
    padding: 15px;
    width: 100%;

    .zone-info-iten {
        display: flex;
        width  : 100%;
        justify-content: space-between;


        h6 {
            width    : 150px;
            color    : theme('colors.gb-gray.500');
            font-size: 15px;
        }

        p {
            font-size: 15px;
            color    : theme('colors.gb-gray.800');
        }
    }
}

.genera-op-button {
    width           : 50px;
    height          : 50px;
    background-color: theme('colors.gb-gray.150');
    border-radius   : 50%;
    border          : 1px theme('colors.gb-gray.500');
    display         : flex;
    justify-content : center;
    align-items     : center;


    transition: background-color .5s;

    &:hover {
        background-color: theme('colors.gb-purple.500');
    }

}

.table-toogle {
    background-color: theme('colors.gb-gray.100');
    min-width       : 60px;
    min-height      : 40px;
    position        : absolute;
    z-index         : 10;
    bottom          : calc(100% + 15px);
    border-radius   : 8px;
    border          : 1px solid theme('colors.gb-gray.400');
    display         : flex;
    justify-content : center;
    flex-direction  : column;
    align-items     : center;
    display         : none;

    .toogle-body {
        min-width       : 60px;
        min-height      : 40px;
        background-color: theme('colors.gb-gray.100');
        border-radius   : 8px;
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        align-items     : center;
    }

    .toogle-arrow {
        width           : 15px;
        height          : 15px;
        background-color: theme('colors.gb-gray.100');
        position        : absolute;
        transform       : rotate(45deg);
        z-index         : -1;
        bottom          : -7.5px;
        border          : 1px solid theme('colors.gb-gray.400');
    }

}