
.button-reduce {
  border-radius: 8px;
  border: 2px solid #4d4d4d;
  padding: 0.6em 1.2em;
  font-size: 1em;
  z-index: 20;
  font-weight: 500;
  font-family: inherit;
  background-color: #292929;
  cursor: pointer;
  transition: border-color 0.25s;
}


.button-reduce:focus,
.button-reduce:focus-visible {
  outline: none;
}

.stage_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 89vw;
  height: 75vh;
  margin: auto;
  position: relative;
}

.stage_container_buttons {
  width: 100%;
  gap: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  margin-left: 20px;
}

.stage_container_filters {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  gap: 7px;
}

.stage_container_filters_filter {
  font-weight: 700;
  border: 2px solid #4d4d4d;
  background: #292929;
  width: 100px;
  padding: 6px 26px;
  border-radius: 8px;
  z-index: 1;
  font-size: 13px;
}

.stage_container_filters_item {
  font-weight: 700;
  background: #292929;
  height: 25px;
  width: 115px;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 26px;
  border-radius: 8px;
  border: 1px solid #2f2f2f;
  z-index: 1;
  font-size: 13px;
}

.stage_container_filters_item_selected {
  border: 2px solid #51bfa6;
}
