.full-event-data {
    // background-color: red;
    width          : 100%;
    display        : flex;
    justify-content: center;
    flex-direction : column;
    align-items    : center;

    .edit-event-form {

        width    : 100%;
        max-width: 800px;
        padding  : 15px;

        .MuiPickerStaticWrapper-content {
            background-color: transparent;

            .css-1dozdou {
                // background-color: red;
                color: theme('colors.gb-gray.500');
            }

            .MuiTypography-root {
                // background-color: blue;
                color: theme('colors.gb-gray.450') !important;
            }

            .MuiSvgIcon-root {
                fill: theme('colors.gb-gray.500');
            }

            .MuiPickersDay-root {
                background-color: transparent;
                color           : theme('colors.gb-gray.900');

                &.Mui-selected {
                    background-color: theme('colors.gb-blue.600');
                }

                &.Mui-disabled {
                    // background-color: red;
                    color: theme('colors.gb-gray.500');
                }
            }
        }

        .MuiClockPicker-root {
            .css-1umqo6f {
                // background-color: red;
                // padding: 100px;
                background-color: transparent;
            }

            .css-12ha4i7,
            .css-a1rc6s,
            .css-118whkv,
            .css-a1rc6s,
            .css-7lip4c {
                background-color: theme('colors.gb-blue.700');
            }

            .css-118whkv,
            .css-2ujp1m,
            .css-2ujp1m {
                border-color: theme('colors.gb-blue.700');
            }

            .css-1flhz3h,
            .css-x9nfkm {
                color: theme('colors.gb-gray.800');
            }

            .css-13go44a-MuiButtonBase-root-MuiIconButton-root,
            .css-dgcnuc,
            .css-ocomm,
            .css-1t8wyba-MuiButtonBase-root-MuiIconButton-root {
                background-color: theme('colors.gb-blue.700');
            }

            .MuiTypography-root {
                color: theme('colors.gb-gray.900');
            }

        }

        .gb-form-control {
            // background-color: red;

            .gb-form-input {
                background-color: theme('colors.gb-gray.75');
                padding         : 10px;
                font-size       : 14px;
                border-radius   : 8px;
                border          : 1px solid theme('colors.gb-gray.250');
                color           : theme('colors.gb-gray.800');

                &:disabled {
                    // background-color: red;
                    background-color: theme('colors.gb-gray.50');
                    border          : 1px solid theme('colors.gb-gray.50');


                }

            }


        }
    }


}

.prev-img {

    // width: 150px;
    img {
        width : 250px;
        height: auto;
    }
}