.chat-h::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 3px;
   }
   .chat-h::-webkit-scrollbar:vertical {
         width:3px;
         height: 3px;
    }
    .chat-h::-webkit-scrollbar-button:increment,.chat-h::-webkit-scrollbar-button {
         display: none;
    } 
    .chat-h::-webkit-scrollbar:horizontal {
         height: 2px;
    }
    .chat-h::-webkit-scrollbar-thumb {
         background-color: gray;
         border-radius: 8px;
    }
    .chat-h::-webkit-scrollbar-track {
         border-radius: 8px;  
    }    