.place-box {


    .zones-box {
        @apply flex flex-col justify-center items-center;
        margin-top: 40px;

        .zona-list {
            background-color: theme('colors.gb-gray.75');
            width           : 100%;
            max-width       : 400px;
            margin          : 5px 0;
            padding         : 15px;
            border-radius   : 5px;
            color           : theme('colors.gb-gray.900');
            border          : 1px solid theme('colors.gb-gray.100');
            transition      : background-color .5s, border-color .5s;

            &.zona-list-disable {
                background-color: theme('colors.gb-gray.75');
                color           : theme('colors.gb-gray.400');


                &:hover {
                    background-color: theme('colors.gb-gray.75');
                    border-color    : theme('colors.gb-gray.100');

                }
            }

            &:hover {
                background-color: theme('colors.gb-pink.200');
                border-color    : theme('colors.gb-gray.700');
            }

            .title-box {
                padding        : 5px 0;
                margin-bottom  : 5px;
                margin-top     : 5px;
                display        : flex;
                justify-content: space-between;
                position       : relative;
                font-size      : 18px;


                .badge {
                    position : absolute;
                    font-size: 13px;
                    bottom   : 30px;
                    color    : theme('colors.gb-gray.500');
                }

            }

            .footer-box {
                // background-color: red;
            }

        }




    }
}

.bloque-box {
    // background-color: theme("colors.gb-gray.75");
    border-radius: 8px;
    padding      : 20px;
    width        : 100%;

}

.chairs {
    @apply flex flex-wrap;
    align-items: center;
    padding    : 2px 0
}

.fila-box {
    @apply flex flex-wrap items-center;
    justify-content: space-between;
    width          : 100%;
    margin-bottom  : 4px;
    padding        : 4px;

}

.fila-id {
    width          : 30px;
    height         : 30px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.place-box {
    margin: 4px;
}

.add-new-zone-form {

    background-color: theme('colors.gb-gray.75');
    padding         : 16px;
    margin          : 16px 0;
    border-radius   : 8px;
    border          : 1px solid theme('colors.gb-gray.250');



    .radio-button-gourp-samll-container {

        span {
            // background-color: red;
            font-size: 14px;
            color    : theme('colors.gb-gray.700');
        }

        .radio-group-button {
            // border: 1px solid theme('colors.gb-gray.300');
        }

        // background-color: red;

    }

    .place-panel-control {

        display       : flex;
        flex-direction: column;

        .label {
            // background-color: red;
            color        : theme('colors.gb-gray.700');
            font-size    : 14px;
            margin-bottom: 5px;
        }

    }

    .place-panel-input {
        background-color: theme('colors.gb-gray.100');
        border          : 1px solid theme('colors.gb-gray.100');
        color           : white;
        padding         : 8px;
        border-radius   : 8px;

        &:focus {
            outline: none;
            border : 1px solid theme('colors.gb-gray.800');
        }

    }

    .charis-form-container {
        // background-color: red;
        border: 1px solid theme('colors.gb-gray.300');


    }
}

.table-bloque-box {


    display        : flex;
    justify-content: center;
    flex-direction : column;
    align-items    : center;

    .add-new-table-button {
        padding      : 5px;
        border       : 1px dashed theme('colors.gb-gray.150');
        border-radius: 5px;
        transition   : background-color .5s;
        color        : theme('colors.gb-gray.350');


        // svg{
        //     fill: red;
        //     stroke: red;
        // }

        &:hover {
            background-color: theme('colors.gb-gray.150');
            color           : theme('colors.gb-gray.350');
        }

        &.long-btn {
            margin-top: 20px;
            width     : 100%;
            max-width : 400px;
        }
    }



    .table-rows {
        display        : flex;
        flex-wrap      : wrap;
        position       : relative;
        padding        : 5px;
        justify-content: space-between;
        width          : 100%;
        align-items    : center;

        .table-zone {
            display  : flex;
            flex-wrap: wrap;
            position : relative;
            padding  : 5px;
        }

        &::after {
            content         : '';
            width           : 100%;
            height          : 1px;
            display         : block;
            background-color: theme('colors.gb-gray.150');
            position        : absolute;
            bottom          : 0;
        }
    }

}

.rectangle-table {
    width              : 50px;
    height             : auto;
    min-height         : 50px;
    // background-color: blue;
    margin             : 5px;
    position           : relative;
    display            : flex;
    justify-content    : center;
    flex-wrap          : wrap;
    align-items        : center;

    .mesa-btns {
        // background-color: red;
        display        : flex;
        flex-direction : column;
        height         : 100%;
        justify-content: space-between;
        align-items    : center;

        svg {
            stroke : white;
            // fill: white !important;
        }

        button {

            width          : 100%;
            display        : flex;
            justify-content: center;
            align-items    : center;
            height         : 50%;

            transition: background-color .5s;

            &:first-child {
                border-radius: 5px 5px 0 0;
            }

            &:last-child {
                border-radius: 0 0 5px 5px;
            }

            &:hover {
                background-color: theme('colors.gb-blue.600');
            }
        }
    }


    .charis-xd {
        display            : flex;
        flex-wrap          : wrap;
        width              : 100%;
        // background-color: red;
        justify-content    : space-between;

        .chair-dx {

            // background-color: red;

            &:nth-child(odd) {
                // background-color: purple;
                margin-right: 10px;

                svg {
                    transform: rotate(-90deg);
                }
            }

            &:nth-child(even) {
                // background-color: aqua;
                margin-left: 10px;

                svg {
                    transform: rotate(90deg);
                }
            }

        }

    }

    .mesa-xd {
        width           : 20px;
        background-color: theme('colors.gb-gray.250');
        position        : absolute;
        height          : 100%;
        z-index         : 10;
        border-radius   : 5px;
    }





}

.add-news-ticket {
    // background-color: red;
    max-width: 500px;

    .add-more-form {
        // background-color: rebeccapurple;
        display       : flex;
        flex-direction: column;
    }

    .add-ticket-btn {
        background-color: theme("colors.gb-pink.600");
        padding         : 10px;
        border-radius   : 8px;

        &.transparent {
            background-color: transparent;
        }
    }

    .form-control {
        display       : flex;
        flex-direction: column;

        // background-color: blue;
        span {
            color: theme("colors.gb-gray.600");
        }

        input {
            background-color: theme("colors.gb-gray.75");
            border-radius   : 8px;
            border          : 1px solid theme("colors.gb-gray.300");
            font-size       : 16px;
            padding         : 10px;
        }


    }
}

.procesing-add-new-zone-form {
    background-color: #370a99;
    border          : 1px solid theme('colors.gb-gray.300');
    border-radius   : 15px;
    padding         : 15px;
    margin-top      : 15px;
    margin-bottom   : 15px;

    h3 {
        color        : theme("colors.gb-gray.850");
        font-size    : 24px;
        text-align   : center;
        border-bottom: 1px solid theme("colors.gb-gray.300");
    }

    p {
        color     : theme('colors.gb-gray.500');
        font-size : 14px;
        text-align: center;
    }

    // MuiSkeleton-root MuiSkeleton-text MuiSkeleton-wave 
    .MuiSkeleton-root {
        background-color: theme("colors.gb-gray.100");

        &::after {
            background: linear-gradient(90deg, transparent, theme("colors.gb-gray.75"), transparent)
        }

    }

}

.succes-add-zone {
    background-color: #136CD1;
    margin          : 15px 0;
    padding         : 15px;
    border-radius   : 15px;
    border          : 1px solid theme("colors.gb-gray.600");

    h3 {
        color        : theme("colors.gb-gray.850");
        font-size    : 24px;
        text-align   : center;
        border-bottom: 1px solid theme("colors.gb-gray.600");
    }

    p {
        color     : theme('colors.gb-gray.800');
        font-size : 14px;
        text-align: center;
    }

    .btn {
        color           : theme('colors.gb-gray.800');
        background-color: theme("colors.gb-blue.600");
        transition      : background-color .5s;
        padding         : 10px;
        width           : 100%;
        max-width       : 200px;
        border-radius   : 8px;
        border          : 1px solid theme("colors.gb-gray.700");

        &:hover {
            background-color: theme('colors.gb-blue.500');
        }

        &:focus {
            outline: none;
        }

    }


}

.error-add-zone {

    background-color: #98030f;
    border          : 1px solid theme('colors.gb-gray.300');
    border-radius   : 15px;
    padding         : 15px;
    margin-top      : 15px;
    margin-bottom   : 15px;

    h3 {
        color        : theme("colors.gb-gray.850");
        font-size    : 24px;
        text-align   : center;
        border-bottom: 1px solid theme("colors.gb-gray.300");
    }

    p {
        color     : theme('colors.gb-gray.500');
        font-size : 14px;
        text-align: center;
    }

    .btn {
        color           : theme('colors.gb-gray.800');
        background-color: theme("colors.gb-blue.600");
        transition      : background-color .5s;
        padding         : 10px;
        width           : 100%;
        max-width       : 200px;
        border-radius   : 8px;
        border          : 1px solid theme("colors.gb-gray.700");

        &.btn-tranparent {
            background-color: transparent;
        }

        &:hover {
            background-color: theme('colors.gb-blue.500');
        }

        &:focus {
            outline: none;
        }

    }

}

.trantition-xd-bg{
 transition: background-color .5s;
}