.gb-button-w-icon {
    @apply flex items-center justify-center;

    background-color: theme('colors.gb-blue.600');
    height          : 50px;
    padding         : 0 10px;
    max-width       : 400px;
    width           : 100%;
    border-radius   : 5px;



    svg {
        width      : 24px;
        height     : 30px;
        fill       : #fff;
        margin-left: 10px;
    }

    &:disabled {
        background-color: the;
    }
}

.gb-button {
    width    : 100%;
    max-width: 200px;
    padding: 20px;
}