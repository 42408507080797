@tailwind base;
@tailwind components;
@tailwind utilities;

@import './modules/event-generator/scss/element-base';
@import './modules/event-generator/scss/link';
@import './modules/event-generator/scss/sidebar';
@import './modules/event-generator/scss/forms';
@import './modules/event-generator/scss/buttons';
@import './modules/event-generator/scss/modal';
@import './modules/event-generator/scss/component/places/place-box';
@import './modules/event-generator/scss/component/places/place-panel';
@import './modules/event-generator/scss/component/event-spech/edit-event-panel';

body {
    background-color: #0d0f10;
}

.jodit-wysiwyg{
    color: #fff !important;
    background: #1e1e1e !important;
}

.p-dropdown {
    border: 1px solid #ffffff4d !important;
}

.jodit-container:not(.jodit_inline) {
    border: 2px solid theme('colors.gb-gray.250') !important;
}