.gb-form-control {
    @apply flex flex-col;

    label {
        color        : theme('colors.gb-gray.850');
        margin-bottom: 10px;
    }

    span {
        // @apply text-gb-gray-350;
        // font-size : 13px;
        // margin-top: 5px;
        // transition: color 0.5s;
    }

    .text-message-error {
        @apply text-gb-red-500;
        margin-top   : 10px;
        margin-bottom: 10px;
    }


    .taxt-c-form {
        background-color: theme('colors.gb-gray.75');
        margin-top      : 20px;
        border          : 1px solid theme('colors.gb-gray.200');
        border-radius   : 8px;
        overflow        : hidden;


        .taxt-c-form-contorl {

            flex-direction: row;


            justify-content: space-between;

            .taxt-c-form-input {
                width: 80%;
            }

        }
    }


}