.p-column-filter-row .p-column-filter-element .p-inputtext {
    width: 150px !important;
}
.p-column-filter-row .p-column-filter-element .p-dropdown {
    width: 200px !important;
}
.p-resizable-column{
    padding-right:70px !important ;
}
.p-selection-column{
    padding-right:20px !important ;

}