h1, h2, h3, h4, h5,h6{
    color: theme('colors.gb-gray.900');
    font-weight: 500;
}

h3{
    font-size: 2rem;
}

h4{
    font-size: 1.5rem;
    font-weight: 400;
}