@mixin spanfrom {}

@import './component/forms/gb-form-control';
@import './component/forms/gb-dropzone';

form {
    color: theme('colors.gb-gray.1050');
}

.jodit-toolbar-editor-collection_mode_horizontal {

    &::after,
    &::before {
        background-color: theme('colors.gb-gray.200') !important;
        // border-color: red !important;
    }
}

.jodit-container:not(.jodit_inline) {
    border: none;
}

.jodit-status-bar {
    background-color: theme('colors.gb-gray.100') !important;
    border-top: none !important;

    .jodit-status-bar__item {

        // background-color: red;
        span {
            color: theme('colors.gb-gray.600');
        }

        a {
            // visibility: hidden;
            display: none;
        }
    }
}

.jodit-toolbar__box {
    background-color: theme('colors.gb-gray.100') !important;
    position: relative !important;
    border-bottom: 1px solid theme('colors.gb-gray.300') !important;


    .jodit-toolbar-editor-collection_size_middle {
        &.jodit-toolbar-editor-collection_mode_horizontal {
            background-image: none;
            background-color: theme('colors.gb-gray.200');
        }
    }

    .jodit-ui-separator {
        border-right: 1px solid theme('colors.gb-gray.250')
    }

    .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
        border-color: transparent;
    }

    .jodit-toolbar-button__button {


        svg {
            fill: theme('colors.gb-gray.850');
            width: 16px;
            height: 16px;
        }



        &:hover:not([disabled]) {
            background-color: theme('colors.gb-gray.50');
            border: none;
            outline: none;

            svg {
                // background-color: blue;
                // color: white;
                fill: white;
            }

            // background-color: red;
        }
    }

}


.dropdown {
    width: 100%;
    position: relative;

    .dropdown-btn {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: theme('colors.gb-gray.100');
        // padding         : 10px;
        padding-left: 15px;
        color: theme('colors.gb-gray.600');
        position: relative;
    }

    .dropdown-constent {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: theme('colors.gb-gray.100')
    }

    .dropdonw-item {
        width: 100%;
        display: flex;
        height: 40px;
        padding: 10px;
        border-radius: 5px;
        // display      : none;

        &:hover {
            cursor: pointer;
            background-color: theme('colors.gb-gray.150')
        }
    }




}



.gb-input {
    outline: none;
    background-color: #1e1e1e;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid theme('colors.gb-gray.350');
    color: theme('colors.gb-gray.900');
    transition: outline 0.5s, border 0.5s, background-color 0.5s;
    // outline         : 1px solid;
    outline-color: transparent;

    &::placeholder {
        color: theme('colors.gb-gray.300');
    }

    &:hover {
        background-color: theme('colors.gb-gray.150');
        outline-color: theme('colors.gb-gray.900');
        border-color: theme('colors.gb-gray.900');

        &~spam {
            color: red;
        }
    }

    &.small {
        font-size: 12px;
        padding: 8px;
    }


    &:focus {
        // outline         : 1px solid;
        // outline-color   : theme('colors.gb-gray.700');
        border-color: theme('colors.gb-gray.700');
        background-color: theme('colors.gb-gray.150');
    }

}

.p-dropdown{
    background-color: #1e1e1e !important;
    border: 1px solid #ffffff4d !important;
}

.p-dropdown-label{
    padding-top: 4px !important;
}


.select-box {
    min-width: 250px;
    height: 150px;

    .check-label {
        width: 100%;
        height: 100%;
        @apply flex justify-center items-center flex-col;
        border: 1px solid;
        border-color: theme('colors.gb-gray.150');
        color: theme('colors.gb-gray.900');
    }

    .check-radio-input {
        // background-color: aqua;
        display: none;

        &:checked~span {
            color: theme('colors.gb-gray.1000');
        }

        &:checked~svg {
            fill: theme('colors.gb-blue.500');
        }
    }


    svg {
        width: 40px;
        height: 40px;
        fill: theme('colors.gb-gray.500');
        // fill: linear-gradient(#e66465, #9198e5);
    }
}

.gb-select-box-container {
    @apply flex flex-wrap justify-center items-center;
    max-width: 800px;

    svg {
        width: 50px;
        height: 50px;
        fill: theme('colors.gb-gray.250');
    }

    .gb-radio-box {
        margin: 10px;
        width: 150px;
        height: 180px;
        margin-top: 40px;
        position: relative;

        .radio-button {

            @apply flex justify-center flex-col items-center;

            background-color: theme('colors.gb-gray.75');
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            border-radius: 8px;
            border: 1.5px solid theme('colors.gb-gray.200');
            transition: outline .5s;
            outline: 2px solid transparent;

            span {
                margin-top: 10px;
                color: theme('colors.gb-gray.600');
            }

            &:hover {
                outline: 2px solid theme('colors.gb-gray.700');
                cursor: pointer;

                svg {
                    fill: theme('colors.gb-gray.700');
                }
            }

        }

        .radio-input {

            display: none;

            &:checked {
                &~.radio-button {
                    background-color: theme('colors.gb-gray.100');
                    outline: 2px solid theme('colors.gb-blue.800');

                    svg {
                        fill: theme('colors.gb-blue.800');
                    }

                    // &:nth-child(odd) {
                    // }





                }
            }
        }


    }


}



.date-piyker-zone {
    background-color: theme('colors.gb-gray.75');
    @apply flex justify-between flex-col;
    width: 100%;
    // max-width: 500px;
    height: 50px;

    button {

        // @apply flex justify-between items-center;
        // width        : 100%;
        // height       : 100%;
        // color        : theme('colors.gb-gray.650');
        // padding      : 0 15px;
        // border       : 2px solid theme('colors.gb-gray.200');
        // border-radius: 5px;
        // transition   : background-color 0.5s;

        .calendar {
            fill: theme('colors.gb-gray.400');
        }

        .chevron {
            // background-color: red;
            transform: rotate(90deg);
            fill: theme('colors.gb-gray.400');
        }



        svg {
            width: 25px;
            height: 25px;
            ;
        }

        &.button-active {
            border-bottom: 2px solid theme('colors.gb-blue.500');

        }

        &:hover {
            background-color: theme('colors.gb-gray.100');
            border-color: theme('colors.gb-gray.900');

            & svg {
                fill: theme('colors.gb-gray.850');
            }
        }

    }
}

.radio-button-gourp-samll-container {

    margin: 20px 0;

    span {
        color: theme('colors.gb-gray.800');
    }



    .radio-group-button {

        display: flex;
        gap: 15px;

        // background-color   : theme('colors.gb-gray.75');
        width: 100%;
        border-radius: 5px;
        margin: 10px 0;
        // border          : 1px solid theme('colors.gb-gray.200');
        overflow: hidden;



        svg {
            width: 20px;
            height: auto;
            fill: theme('colors.gb-gray.500');
        }

        .radio-button {

            display: flex;


            .box {

                @apply flex justify-center items-center;

                background-color: theme('colors.gb-gray.75');
                display: flex;
                width: 100%;
                align-items: center;

                width: 72px;
                border-radius: 8px;
                height: 64px;


                &:hover {
                    cursor: pointer;
                }

            }

            .check {

                display: none;



                &:checked {
                    &~.box {
                        background-color: theme('colors.gb-gray.100');

                        svg {
                            fill: theme('colors.gb-gray.1000');
                        }
                    }

                    &~.box-type-location {
                        background-color: theme('colors.gb-gray.100');
                    }
                }
            }
        }
    }

    @media(max-width:780px) {
        .locationType {
            display: grid;
            gap: 7px;
        }

        .tableType {
            display: flex;
            overflow: scroll;
            width: 90vw;
            gap: 7px;
        }

    }



}


.ui-check {
    // background-color: red;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 !important;

    // font-size       : 12px !important;
    .MuiTypography-root {
        // background-color: blue;
        font-size: 12px;
    }
}

.charis-form-container,
.table-form-container {
    @apply flex justify-center items-center;
    width: 100%;
    // background-color: theme('colors.gb-gray.75');
    border-radius: 4px;
    border: 1px solid #3e3e3e;
    height: 55px;

    &.erros-active {
        // margin-bottom: 50px;
    }

    .MuiFormControlLabel-root {
        width: 100%;
        background-color: red;
    }


    .gb-form-control {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;


        .errors-message {
            position: absolute;
            color: theme('colors.gb-red.600');
            width: 100%;
            padding: 5px;
        }



        label {
            display: flex;
            margin: 0 5px;
            position: relative;
            z-index: 10;
            justify-content: center;
            align-items: center;
            padding: 6px;
            // background-color: red;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }


        }


        .box-input {
            display: block;
            width: 100%;
            height: 100%;
            background-color: transparent;
            position: absolute;
            z-index: -5;
            padding: 0;
            margin: 0;
        }

        .gb-input {
            // background-color: theme('colors.gb-gray.75');
            background-color: transparent;
            border-radius: 5px;
            padding: 4px 6px;
            font-size: 12px;
            width: 100%;
            border: none;


            &:focus~.box-input {
                background-color: theme('colors.gb-gray.100');
            }

            // border: ;
        }
    }
}

.taxt-form-zone,
.general-place-form {


    // background-color: purple;
    .gb-form-control {
        margin: 5px 0;

        label {
            display: flex;
            flex-direction: column;
        }

        .gb-input {
            // margin-top: 5px;
            // font-size : 12px;
            // padding   : 5px 10px;
        }
    }

    svg {
        // background-color: red;
        fill: theme('colors.gb-gray.200');
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .billing-container {
        background-color: theme('colors.gb-gray.75');
        padding: 15px;
        border-radius: 8px;
        border: 1px solid theme('colors.gb-gray.200');

        .gb-input {
            border: transparent;
            font-size: 12px;
            // text-align: end;
        }

        .billing-item {
            .gb-input {
                padding: 4px;
                width: 80px;
            }

            label {
                @apply flex justify-between items-center;
                padding-bottom: 5px;
                margin-top: 5px;
                border-bottom: 1px solid theme('colors.gb-gray.150');

                &:hover {
                    svg {
                        // background-color: red;
                        fill: theme('colors.gb-gray.800');
                    }
                }

                // font-size: 12x;
                span {
                    // background-color: red;
                    font-size: 12px;
                    color: theme('colors.gb-gray.800');
                }
            }
        }
    }
}

.button-option-box {
    @apply flex flex-col;
    width: 100%;
    border: 1px solid theme('colors.gb-gray.200');
    border-radius: 8px;
    overflow: hidden;
    background-color: theme('colors.gb-gray.75');


    .button-option {
        // background-color: red;
        padding: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &.button-option-active {
            background-color: theme('colors.gb-blue.700');

            .icon {
                svg {
                    fill: theme('colors.gb-gray.1000');
                }
            }
        }

        span {
            color: theme('colors.gb-gray.800');
        }

    }
}

.gb-error-message {
    color: theme('colors.gb-red.700');
    font-size: 13px;
}

.progress-bar {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    position: relative;

    .progres-number {
        position: absolute;
    }
}


.add-new-rows {

    color: theme('colors.gb-gray.750');
    width: 100%;
    // background-color: blue;
    max-width: 500px;

    .add-text {
        // background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // background-color: red;
        padding: 10px;
        transition: background-color 0.5s;
        border-radius: 8px;

        &:hover {
            background-color: theme('colors.gb-gray.150');
        }


        &::before,
        &::after {
            content: '';
            display: block;
            background-color: theme('colors.gb-gray.250');
            width: 100px;
            height: 1px;
            position: absolute;
        }

        &::after {
            right: 15px;
        }

        &::before {
            left: 15px;
        }
    }
}


.button-place-form {
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid theme('colors.gb-gray.550');
    transition: background-color .5s, border .5s;
}

.place-form {

    padding: 18px;


    .place-form-control {
        display: flex;
        flex-direction: column;

    }

    .place-form-label {
        color: theme('colors.gb-gray.700');
        font-size: 14px;
        margin-bottom: 6px;

        &.erros-label {
            color: theme('colors.gb-red.500');
        }

    }

    .gb-place-input {
        background-color: theme('colors.gb-gray.100');
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
        color: theme('colors.gb-gray.700');
        border: 1px solid theme('colors.gb-gray.450');
        transition: border .5s;

        &:focus {
            outline: none;
            border: 1px solid theme('colors.gb-blue.800');
        }

        &::placeholder {
            color: theme('colors.gb-gray.500');
        }



    }

    .text-area {
        resize: none;
        height: 160px;
        overflow: auto;

    }

    .input-error {
        border: 1px solid theme('colors.gb-red.600');
    }

    .gb-place-text-info {
        color: theme('colors.gb-gray.500');
        margin-top: 8px;
        font-size: 14px;
        text-align: justify;
    }

    .gb-place-text-message-error {
        color: theme('colors.gb-red.500');
        font-size: 14px;
        text-align: justify;
    }


    .MuiTypography-root {
        font-size: 12px;
    }

    .taxt-zone {


        .place-form-control {
            position: relative;
            margin: 18px 0;
        }

        .place-form-label {
            transform: translateY(-16px);
            position: absolute;
            top: 0;
            left: 12px;
            font-size: 12px;

            // background-color: theme('colors.gb-gray.75');
            &::after {}
        }

        .gb-place-input {
            padding: 6px 10px;
            font-size: 13px;
            // border-top: none;
            border: none;

            &:focus {
                background-color: theme('colors.gb-gray.200');
            }

        }
    }

}