.left-half {
    background: url('https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Ffondos%2Fglobalboletos_Event_producers._Color_Royal_4K_Purple_fccdc885-6cbc-4060-94f7-989c7b4f1b0c%20(1)_11zon.webp?alt=media&token=81376ed0-f2b9-43a2-82de-a14db3be4691');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.right-half-container {
    border: 1px solid #f4f4f42b;
    border-radius: 5px;
    padding: 2rem;
}

@media(max-width:768px) {
    .left-half {
        display: none;
    }
    .right-half-container{
        padding: 1rem;
    }
}