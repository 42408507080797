.gb-dropzone {
    // background-color: rebeccapurple;
    background-color: theme('colors.gb-gray.75');
    width          : 100%;
    min-height     : 200px;
    padding        : 15px;
    display        : flex;
    flex-direction : column;
    border         : 1px dashed theme('colors.gb-gray.200');
    border-radius  : 8px;
    justify-content: center;
    align-items    : center;
    color          : theme('colors.gb-gray.800');

    span {
        color    : theme('colors.gb-gray.350');
        font-size: 14px;
    }

    .drag-button {
        background-color: theme('colors.gb-gray.250');
        padding         : 10px;
        border-radius   : 8px;
        border          : 1px solid theme('colors.gb-gray.400');
        font-size       : 12px;
        transition      : background-color .5s;



        &:hover {
            background-color: theme('colors.gb-blue.500');
        }
    }

    &.on-focus {
        background-color: theme('colors.gb-gray.75');
        border-color    : theme('colors.gb-gray.900');

    }

    &.on-drag-accept {
        border-color: theme('colors.gb-gray.900');
    }

    &.on-drag-rejet {
        border-color: theme('colors.gb-red.600');
    }
}